import React, { useState, useEffect, useRef } from "react";
import { withRouter, useLocation, useHistory } from "react-router-dom";
import { Modal, Button, Box, Typography } from "@material-ui/core";
import { notAuthScreen, signOut } from "../../services/Auth";

import { withApollo } from "@apollo/client/react/hoc";

import DefaultHeader from "./DefaultHeader";
import DefaultNav from "./DefaultNav";

import { makeStyles, withTheme } from "@material-ui/core/styles";
import classNames from "classnames";
import { TENANT } from "../../config";

// DISPLAY_LOGOUT_MODAL_THRESHOLD is the threshold in milliseconds of inactivity we allow
// before displaying the "logout warning" modal.
const minutes = TENANT == "dev" || "qa" ? 5 : 15;
const DISPLAY_LOGOUT_MODAL_THRESHOLD = minutes * 60 * 1000;
// FORCE_LOGOUT_THRESHOLD is the threshold at which point the user will be forcefully logged out.
// The threshold is double the time of the DISPLAY_LOGOUT_MODAL_THRESHOLD.
//
// Under normal app usage, the DISPLAY_LOGOUT_MODAL_THRESHOLD will be met and a logout warning
// modal will be presented, allowing the user to abort an auto-logout. The FORCE_LOGOUT_THRESHOLD
// can be met if the user closes the application and returns to it after a period of time, at which
// point we would want to bypass the modal completely and force a user logout.
const FORCE_LOGOUT_THRESHOLD = DISPLAY_LOGOUT_MODAL_THRESHOLD * 2;
const CHECK_INTERVAL = 1500;
const STORE_KEY = "lastAction";
const COUNTDOWN_INTERVAL = 10;

interface AppWrapperProps {
  children: any;
}
const AppWrapper = ({ children }: AppWrapperProps) => {
  const [showInactivityModal, setShowInactivityModal] = useState(false);
  const [inactivityTimer, setInactivityTimer] = useState(COUNTDOWN_INTERVAL);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [expandedSub, setExpandedSub] = useState("");

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const inactivityCheckIntervalIdRef = useRef(null);
  const countdownIntervalIdRef = useRef(null);

  const getLastAction = () => parseInt(sessionStorage.getItem(STORE_KEY));
  const setLastAction = lastAction =>
    sessionStorage.setItem(STORE_KEY, lastAction.toString());
  const reset = () => {
    setLastAction(Date.now());
  };

  const check = () => {
    if (!notAuthScreen(location.pathname)) {
      //if it IS an auth screen we don't check last action
      return;
    }

    const lastAction = getLastAction();
    const now = Date.now();

    // logout time threshold is the amount of time allowed to be idle before we force a log out
    const logoutTimeThresholdDiff = lastAction + FORCE_LOGOUT_THRESHOLD - now;
    if (logoutTimeThresholdDiff < 0) {
      signOut();
      return;
    }

    // time threshold for showing logout countdown, allowing user to cancel and stay logged in
    const timeLeftUntilModalDropdownDiff =
      lastAction + DISPLAY_LOGOUT_MODAL_THRESHOLD - now;
    if (timeLeftUntilModalDropdownDiff < 0 && !showInactivityModal) {
      setShowInactivityModal(true);
      startCountdown();
    }
  };

  const startCountdown = () => {
    clearInterval(countdownIntervalIdRef.current); // clear previous interval if any
    countdownIntervalIdRef.current = setInterval(() => {
      setInactivityTimer(prevTimer => {
        const updatedTimer = prevTimer - 1;
        return updatedTimer;
      });
    }, 1000);
  };

  const toggleInactivityModal = () => {
    setShowInactivityModal(prevState => !prevState);
    resetTimer();
    if (!showInactivityModal) {
      startCountdown();
    } else {
      clearInterval(countdownIntervalIdRef.current);
    }
  };

  const resetTimer = () => setInactivityTimer(COUNTDOWN_INTERVAL); //reset timer to initial value

  const navigateToAdmin = () => {
    history.push("/admin");
  };

  const navigateToAccountSettings = () => {
    history.push("/settings");
  };

  const toggleIsNavOpen = () => {
    setIsNavOpen(!isNavOpen);
  };

  const toggleIsMobileNavOpen = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  const updateSelectedSubNav = selected => {
    setExpandedSub(expandedSub === selected ? "" : selected);
  };

  // add event listeners for detecting user activity on mount, clean up on unmount
  // authenticate and initialize user action
  useEffect(() => {
    const listener = () => reset();
    document.body.addEventListener("click", listener);
    document.body.addEventListener("mouseover", listener);
    document.body.addEventListener("mouseout", listener);
    document.body.addEventListener("keydown", listener);
    document.body.addEventListener("keyup", listener);
    document.body.addEventListener("keypress", listener);

    const handleNavigation = () => {
      try {
        reset();
      } catch (err) {
        if (!location.href.includes("auth")) {
          signOut();
        }
      }
    };
    handleNavigation();

    return () => {
      document.body.removeEventListener("click", listener);
      document.body.removeEventListener("mouseover", listener);
      document.body.removeEventListener("mouseout", listener);
      document.body.removeEventListener("keydown", listener);
      document.body.removeEventListener("keyup", listener);
      document.body.removeEventListener("keypress", listener);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!showInactivityModal) {
        check();
      }
    }, CHECK_INTERVAL);
    inactivityCheckIntervalIdRef.current = intervalId;

    return () => {
      clearInterval(inactivityCheckIntervalIdRef.current);
      clearInterval(countdownIntervalIdRef.current);
    };
  }, []);

  useEffect(() => {
    if (inactivityTimer === 0) {
      signOut();
    }
  }, [inactivityTimer]);

  return (
    <div className="app-body">
      <DefaultNav
        isNavOpen={isNavOpen}
        isMobileNavOpen={isMobileNavOpen}
        toggleNav={toggleIsNavOpen}
        expandedSub={expandedSub}
        updateSelectedSubNav={updateSelectedSubNav}
        toggleMobileNav={toggleIsMobileNavOpen}
        path={location?.pathname}
      />
      <main
        className={classNames(classes.content, {
          [classes.contentShift]: isNavOpen,
          [classes.narrow]: isNavOpen
        })}
      >
        <DefaultHeader
          signOut={signOut}
          navigateToAdmin={navigateToAdmin}
          navigateToAccountSettings={navigateToAccountSettings}
          toggleNav={toggleIsNavOpen}
          toggleMobileNav={toggleIsMobileNavOpen}
        />
        <div className={classes.container}>{children}</div>
      </main>

      <Modal open={showInactivityModal} onClose={toggleInactivityModal}>
        <Box className={classes.box}>
          <Typography>Still There?</Typography>
          <div className={classes.logOut}>
            <Typography variant={"h6"}>
              You will be logged out after:
            </Typography>
            <Typography variant={"h5"} className={classes.redText}>
              {inactivityTimer}
            </Typography>
          </div>
          <div className={classes.buttonContain}>
            <Button
              color="secondary"
              variant="contained"
              onClick={toggleInactivityModal}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  footerWidth: {
    width: "calc(100% - 55px)",
    ["@media (max-width:1280px)"]: {
      width: "100%"
    }
  },
  container: {
    display: "flex",
    height: "100%",
    padding: "0 5px",
    width: "100%",
    marginRight: "auto",
    marginLeft: "auto",
    maxHeight: "calc(100vh - 64px)"
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.down("md")]: {
      marginLeft: 0
    },
    width: "96%",
    height: "100vh",
    display: "flex",
    flexDirection: "column"
  },
  narrow: {
    width: "81%"
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  box: {
    backgroundColor: "white",
    width: "25%",
    height: "25%",
    margin: "0 auto",
    marginTop: "20%",
    padding: "2%",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch"
  },
  logOut: {
    textAlign: "center",
    flexGrow: 1,
    paddingTop: "7%"
  },
  buttonContain: {
    display: "flex",
    flexDirection: "row-reverse"
  },
  redText: {
    color: "#f86c6b",
    fontWeight: 600
  }
}));

export default withRouter(withTheme(withApollo(AppWrapper)));
