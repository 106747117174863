import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

const OrxDrawer = ({
  classes,
  isOpen,
  toggleDrawer,
  title,
  children,
  additionalHeaderElements,
  keepMounted
}) => {
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={toggleDrawer}
      classes={{ paper: classes.paperOverride }}
      data-testid="orx-drawer"
      keepMounted={keepMounted}
    >
      <div className={classes.titleContain}>
        <Typography variant={"h5"}>{title}</Typography>
        <div style={{ flexGrow: 1 }}>{additionalHeaderElements}</div>
        <IconButton
          data-testid="orx-drawer-close-button"
          onClick={toggleDrawer}
        >
          <CloseIcon />
        </IconButton>
      </div>
      {children}
    </Drawer>
  );
};
OrxDrawer.propTypes = {
  classes: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.element,
  additionalHeaderElements: PropTypes.element,
  /** keepMounted keeps the children components mounted in the DOM
   * even when the Drawer is closed. This prevents re-rendering the
   * children each time the drawer is opened.
   *
   * The reasons to use it are for more performant opens, or to retain
   * state in the children across opens.
   */
  keepMounted: PropTypes.bool
};
OrxDrawer.defaultProps = {
  keepMounted: false
};

const styles = theme => ({
  paperOverride: {
    width: "66%"
  },
  titleContain: {
    display: "flex",
    alignItems: "center",
    padding: 10,
    marginLeft: 14,
    background: "white",
    position: "-webkit-sticky",
    position: "sticky",
    top: 0,
    zIndex: 50,
    borderBottom: ".02rem solid #e2e2e2"
  }
});

export default withStyles(styles)(OrxDrawer);
