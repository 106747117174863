import { createMuiTheme } from "@material-ui/core/styles";
import "typeface-nunito";
import "typeface-nunito-sans";

export default createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: "Nunito Sans, Roboto, sans-serif"
  },
  palette: {
    primary: {
      main: "#546F7A",
      light: "#AFAFAF",
      active: "#027CBA",
      inactive: "#8F9399"
    },
    secondary: {
      main: "#027CBA",
      light: "#027CBA",
      contrastText: "#eee"
    },
    action: {
      selected: "#E4F1F8"
    }
  },
  overrides: {
    MuiOutlinedInput: {
      input: {
        fontSize: "14px"
      }
    }
  },
  drawerWidth: 250,
  navDrawerWidth: 55
});
