import React from "react";
import PrivateRoute from "./PrivateRoute";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useQuery } from "@apollo/client";
import { GET_CURRENT_USER } from "../graphql/queries";

import { canRenderAdminPages } from "../services/Auth";

const AdminRoute = ({ children, ...rest }) => {
  const { loading, error, data } = useQuery(GET_CURRENT_USER);

  if (loading) return <CircularProgress />;
  if (error) return "There was an error authenticating";
  if (!data) return "No data";

  const { getCurrentUser: user } = data;

  return (
    <PrivateRoute {...rest}>
      {canRenderAdminPages(user.authorized_pages) ? children : "Not Authorized"}
    </PrivateRoute>
  );
};
export default AdminRoute;
