import React, { useState, useEffect } from "react";
import { currentAuthenticatedUser } from "../../services/Auth";
import { SSO_PREFIX, isSSO } from "../../config";

export const getUsername = user => {
  if (user.username) {
    let username = user.username;
    if (isSSO) {
      username = username.split(SSO_PREFIX).join("").split("@")[0];
    }
    return username;
  } else {
    return false;
  }
};

export const createSsoUsername = email => {
  return SSO_PREFIX.concat(email);
};

const CurrentUser = () => {
  const [username, setUsername] = useState(null);

  useEffect(() => {
    const setUsernameBasedOnCurrentUser = async () => {
      const user = await currentAuthenticatedUser();
      if (user) {
        setUsername(getUsername(user));
      }
    };
    setUsernameBasedOnCurrentUser();
  }, []);
  return username;
};
export default CurrentUser;
