import _ from "lodash/lodash";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
dayjs.extend(LocalizedFormat);
import numbro from "numbro";

class Common {
  static pluralize(count, singleVariation, pluralVariation) {
    if (count === 1) {
      return singleVariation;
    }

    return pluralVariation;
  }

  static quellFn(fn) {
    return function (e, ...args) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      return fn(...args);
    };
  }

  static formatters = {
    asFullDate: val => {
      if (!val) {
        return "";
      }
      return dayjs(val).format("MM/DD/YYYY");
    },
    asFullDateTime: val => {
      if (!val) {
        return "";
      }
      return dayjs(val).format("LLLL");
    },
    asStatus: val => {
      if (!val) {
        return "Draft";
      }
      switch (val) {
        case 0:
          return "Draft";
        case 1:
          return "Submitted";
        case 2:
          return "Deleted";
      }
      return "(?)";
    },
    asCurrency: val => {
      if (!val) {
        val = 0;
      }
      return numbro(val).format("$0,0.00");
    },
    asInteger: val => {
      if (!val) {
        return numbro(0).format("0,0");
      }
      return numbro(val).format("0,0");
    },
    asDecimal: val => {
      if (!val) {
        return numbro(0).format("0,0.00");
      }
      return numbro(val).format("0,0.00");
    },
    asPercent: val => {
      if (!val || val === 0) {
        return numbro(0).format("0%");
      }
      return numbro(val).format("0.00%");
    },
    asMonth: val => {
      if (!val) {
        return "";
      }
      const months = Common.options.months;
      return _.find(months, x => {
        return x.value == parseInt(val);
      }).label;
    },
    asGender: val => {
      if (!val) {
        return "Male";
      }
      switch (val) {
        case 0:
          return "Male";
        case 1:
          return "Female";
      }
      return "(?)";
    },
    asShortagePriority: val => {
      switch (val) {
        case 1:
          return "Monitor";
          break;
        case 2:
          return "Caution";
          break;
        case 3:
          return "Critical";
          break;
        case 4:
          return "Unavailable";
          break;
        default:
          return "Not Set";
          break;
      }
      return "(?)";
    },
    asShortageStatus: val => {
      switch (val) {
        case 0:
          return "Activated";
          break;
        case 1:
          return "Configured";
          break;
        case 2:
          return "Decommissioned";
          break;
        default:
          return "Decomissioned";
          break;
      }
      return "(?)";
    },
    asPurchaseOrderStatus: val => {
      switch (val) {
        case 1:
          return "Open";
          break;
        case 2:
          return "Submitted";
          break;
        case 3:
          return "Fulfilled";
          break;
        default:
          return "?";
          break;
      }
      return "(?)";
    }
  };

  static renderers = {
    genderRenderer: option => {
      return Common.formatters.asGender(option.value);
    }
  };

  static calculations = {
    average: (di, dv) => {
      if (di && dv && dv > 0) {
        return parseFloat(di) / parseFloat(dv);
      }
    }
  };

  static messages = {
    error: "An error occurred.",
    saveSuccess: "Save success!",
    saveServerError: "Unable to save. A server error occured.",
    saveFormInvalid: "Unable to save. Form is invalid",
    deleteServerError: "Unable to delete. A server error occured.",
    saveError: "Unable to save.",
    validationRequired: "Field is required.",
    validationDateRange:
      "The end date you selected must be the same or after the start date selected.",
    validationInt: "Must be a valid whole number",
    validationCurrency: "Must be a valid currency amount",
    validationNumber: "Must be a valid number",
    validationMonth: "Must be a valid month",
    validationYear: "Must be a valid year",
    loginFailed:
      "Login attempt failed. Check your username and password and try again",
    uploadError:
      "An error occured uploading the document. Please check the template and try again."
  };

  static options = {
    years: [
      { value: 2017, label: "2017" },
      { value: 2016, label: "2016" },
      { value: 2015, label: "2015" },
      { value: 2014, label: "2014" }
    ],
    months: [
      { value: 1, label: "January" },
      { value: 2, label: "February" },
      { value: 3, label: "March" },
      { value: 4, label: "April" },
      { value: 5, label: "May" },
      { value: 6, label: "June" },
      { value: 7, label: "July" },
      { value: 8, label: "August" },
      { value: 9, label: "September" },
      { value: 10, label: "October" },
      { value: 11, label: "November" },
      { value: 12, label: "December" }
    ],
    quarters: [
      { value: 1, label: "Q1" },
      { value: 2, label: "Q2" },
      { value: 3, label: "Q3" },
      { value: 4, label: "Q4" }
    ],
    status: [
      { value: "", label: "All" },
      { value: "0", label: "Draft" },
      { value: "1", label: "Complete" }
    ],
    gender: [
      { value: 0, label: "Male" },
      { value: 1, label: "Female" }
    ],
    states: [
      { value: "AL", label: "Alabama" },
      { value: "AK", label: "Alaska" },
      { value: "AZ", label: "Arizona" },
      { value: "AR", label: "Arkansas" },
      { value: "CA", label: "California" },
      { value: "CO", label: "Colorado" },
      { value: "CT", label: "Connecticut" },
      { value: "DE", label: "Delaware" },
      { value: "DC", label: "District of Columbia" },
      { value: "FL", label: "Florida" },
      { value: "GA", label: "Georgia" },
      { value: "HI", label: "Hawaii" },
      { value: "ID", label: "Idaho" },
      { value: "IL", label: "Illinois" },
      { value: "IN", label: "Indiana" },
      { value: "IA", label: "Iowa" },
      { value: "KS", label: "Kansas" },
      { value: "KY", label: "Kentucky" },
      { value: "LA", label: "Louisiana" },
      { value: "ME", label: "Maine" },
      { value: "MD", label: "Maryland" },
      { value: "MA", label: "Massachusetts" },
      { value: "MI", label: "Michigan" },
      { value: "MN", label: "Minnesota" },
      { value: "MS", label: "Mississippi" },
      { value: "MO", label: "Missouri" },
      { value: "MT", label: "Montana" },
      { value: "NE", label: "Nebraska" },
      { value: "NV", label: "Nevada" },
      { value: "NH", label: "New Hampshire" },
      { value: "NJ", label: "New Jersey" },
      { value: "NM", label: "New Mexico" },
      { value: "NY", label: "New York" },
      { value: "NC", label: "North Carolina" },
      { value: "ND", label: "North Dakota" },
      { value: "OH", label: "Ohio" },
      { value: "OK", label: "Oklahoma" },
      { value: "OR", label: "Oregon" },
      { value: "PA", label: "Pennsylvania" },
      { value: "RI", label: "Rhode Island" },
      { value: "SC", label: "South Carolina" },
      { value: "SD", label: "South Dakota" },
      { value: "TN", label: "Tennessee" },
      { value: "TX", label: "Texas" },
      { value: "UT", label: "Utah" },
      { value: "VT", label: "Vermont" },
      { value: "VA", label: "Virginia" },
      { value: "WA", label: "Washington" },
      { value: "WV", label: "West Virginia" },
      { value: "WI", label: "Wisconsin" },
      { value: "WY", label: "Wyoming" }
    ]
  };

  static localStorageKeys = {};

  static actionTypes = {};

  static navigation = {};
}

export default Common;
