import React, { Component } from "react";
import PropTypes from "prop-types";
import logo from "../../assets/img/brand/white@2x.png";
import { Query } from "@apollo/client/react/components";
import {
  GET_CURRENT_USER,
  GET_FIRST_ORGANIZATION
} from "../../graphql/queries";
import { canRenderAdminPages } from "../../services/Auth";
// Material
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Typography from "@material-ui/core/Typography";
import withWidth from "@material-ui/core/withWidth";
import Notifications from "../../components/Notifications";
import SupportLink from "../../components/SupportLink";
import CurrentUser from "../../components/CurrentUser";

import * as Sentry from "@sentry/browser";

class DefaultHeader extends Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null
  };

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { classes } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const appTenant = process.env.REACT_APP_TENANT;
    const appEnvironment =
      process.env.REACT_APP_ENVIRONMENT === "production"
        ? null
        : process.env.REACT_APP_ENVIRONMENT;

    const renderMenu = showAdminOptions => (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            this.handleMenuClose();
            this.props.navigateToAccountSettings();
          }}
        >
          Account Settings
        </MenuItem>
        {showAdminOptions && (
          <MenuItem
            onClick={() => {
              this.handleMenuClose();
              this.props.navigateToAdmin();
            }}
          >
            Admin Panel
          </MenuItem>
        )}
        <MenuItem onClick={this.props.signOut}>Logout</MenuItem>
      </Menu>
    );

    return (
      <div className={classes.root} style={{ marginTop: 0 }}>
        <AppBar position="static">
          <Toolbar disableGutters={true}>
            <div className={classes.mobileMenuButton}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.props.toggleMobileNav}
              >
                <MenuIcon />
              </IconButton>
            </div>

            <div className={classes.menuButton}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.props.toggleNav}
              >
                <MenuIcon />
              </IconButton>
            </div>

            <div style={{ marginRight: 25 }}>
              <img src={logo} height={40} />
            </div>

            {appEnvironment && (
              <Typography variant="h6" color="inherit" className={classes.grow}>
                {`APP ENVIRONMENT: ${appTenant} - ${appEnvironment.toUpperCase()}`}
              </Typography>
            )}

            <div className={classes.grow} />

            <div className={classes.profileButton}>
              <Notifications />
              <Query query={GET_FIRST_ORGANIZATION}>
                {({ loading, error, data }) => {
                  if (loading) return renderMenu(false);
                  if (error) {
                    Sentry.captureMessage(
                      `Failed to load first org from GraphQL. Error: ${JSON.stringify(
                        error
                      )}`
                    );
                    return renderMenu(false);
                  }
                  if (!data) {
                    Sentry.captureMessage(
                      `Failed to load data for first org from GraphQL.`
                    );
                    return renderMenu(false);
                  }
                  const { getFirstOrganization: org } = data;

                  return (
                    <SupportLink
                      isMenuOpen={isMenuOpen}
                      isMDXCustomer={org.supportedByMDX}
                    />
                  );
                }}
              </Query>
              <IconButton
                aria-owns={isMenuOpen ? "material-appbar" : undefined}
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
                color="inherit"
                style={{ padding: 3, marginRight: 7 }}
              >
                <AccountCircle />
              </IconButton>
              <Typography className={classes.user}>
                <CurrentUser />
              </Typography>
            </div>
          </Toolbar>
        </AppBar>
        <Query query={GET_CURRENT_USER}>
          {({ loading, error, data }) => {
            if (loading) return renderMenu(false);
            if (error) {
              Sentry.captureMessage(
                `Failed to load current user from GraphQL. Error: ${JSON.stringify(
                  error
                )}`
              );
              return renderMenu(false);
            }
            if (!data) {
              Sentry.captureMessage(
                `Failed to load current user data from GraphQL.`
              );
              return renderMenu(false);
            }
            const { getCurrentUser: user } = data;

            return renderMenu(canRenderAdminPages(user.authorized_pages));
          }}
        </Query>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    display: "flex",
    height: "64px"
  },
  grow: {
    flexGrow: 1
  },
  mobileMenuButton: {
    marginLeft: 12,
    marginRight: 20,
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "flex"
    }
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
    display: "flex",
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  profileButton: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  user: {
    alignSelf: "center",
    paddingRight: "2em"
  }
});

DefaultHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  signOut: PropTypes.func.isRequired,
  navigateToAdmin: PropTypes.func.isRequired,
  navigateToAccountSettings: PropTypes.func.isRequired,
  toggleNav: PropTypes.func.isRequired,
  toggleMobileNav: PropTypes.func.isRequired
};

export default withStyles(styles)(withWidth()(DefaultHeader));
