import React, { useEffect } from "react";
import { Hub } from "aws-amplify";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import logo from "../../assets/img/brand/OrbitalRX-logo-h-fc-flat-rgb.png";
import { useSnackbar } from "notistack";
import { SSO_LOGIN } from "../../config";

const SSOLogin = () => {
  const classes = useStyles();

  const redirect = SSO_LOGIN;
  const { enqueueSnackbar } = useSnackbar();

  const handleSSOLogin = e => {
    e.preventDefault();
    try {
      window.location.assign(redirect);
    } catch (err) {
      console.log(err);
      enqueueSnackbar(`Error signing in with SSO`, {
        variant: "error"
      });
    }
  };
  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Error", data);
          break;
      }
    });
  }, []);
  return (
    <div className={classes.container}>
      <img alt="OrbitalRX Logo" src={logo} className={classes.logo} />
      <form className={classes.form} onSubmit={handleSSOLogin}>
        <Button
          color="primary"
          variant="contained"
          style={{ width: "100%", marginTop: "15px" }}
          type="submit"
        >
          SIGN IN
        </Button>
      </form>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  form: {
    display: "flex",
    flexWrap: "wrap",
    margin: "0 auto",
    width: "100%"
  },
  logo: {
    maxHeight: "90px",
    margin: "35px auto 65px auto"
  },
  signIn: {
    textAlign: "center",
    color: "#404041"
  },
  textField: {
    width: "100%"
  },
  inputWrapper: {
    width: "100%",
    marginBottom: "15px"
  }
});

export default SSOLogin;
