import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import LoginComponent from "./Login";
import SSOLogin from "./SSOLogin";
import ForgotPasswordComponent from "./ForgotPassword";
import ConfirmForgotPasswordComponent from "./ConfirmForgotPassword";
import ConfirmSignupComponent from "./ConfirmSignup";
import { isSSO } from "../../config";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";

const Authenticator = () => {
  const classes = useStyles();

  return (
    <div className={classes.marginTop}>
      <Card className={classes.container}>
        <Switch>
          <Route path="/auth/login" exact>
            {isSSO && <SSOLogin />}
            {!isSSO && <LoginComponent />}
          </Route>
          <Route path="/auth/orbitalrxlogin">
            {/* orbitalrx specific login page user/pw */}
            {isSSO && <LoginComponent />}
          </Route>
          <Route path="/auth/password-reset" exact>
            <ForgotPasswordComponent />
          </Route>
          <Route path="/auth/confirm-password-reset" exact>
            <ConfirmForgotPasswordComponent />
          </Route>
          <Route path="/auth/confirm-signup" exact>
            <ConfirmSignupComponent />
          </Route>
          <Route path="/auth" render={() => <Redirect to="/auth/login" />} />
        </Switch>
      </Card>
    </div>
  );
};

const useStyles = makeStyles({
  marginTop: {
    marginTop: "5%"
  },
  container: {
    width: "33%",
    padding: 25,
    height: "auto",
    margin: "0 auto",
    backgroundColor: "#fff",
    borderRadius: "7px"
  }
});

export default Authenticator;
