const minLength = 8;

export default class PasswordValidator {
  constructor(password) {
    this.password = password;
    this.minLength = minLength;
  }

  valid() {
    return (
      this.validateMinLength() &&
      this.validateHasLowercase() &&
      this.validateHasUppercase() &&
      this.validateHasSymbol() &&
      this.validateHasNumber()
    );
  }

  validateMinLength() {
    return this.password.length >= this.minLength;
  }

  validateHasLowercase() {
    const regex = /[a-z]/;
    return regex.test(this.password);
  }

  validateHasUppercase() {
    const regex = /[A-Z]/;
    return regex.test(this.password);
  }

  validateHasSymbol() {
    const regex = /[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=]/;
    return regex.test(this.password);
  }

  validateHasNumber() {
    const regex = /[\d]/;
    return regex.test(this.password);
  }
}
