import React from "react";

export const Loading = () => {
  return <div>Loading...</div>;
};

export const Admin = React.lazy(() => import("../views/Admin"));

export const ShortageListings = React.lazy(
  () => import("../views/Shortages/ListingsWrapper")
);

export const Dashboard = React.lazy(() => import("../views/Dashboard"));

export const Tasks = React.lazy(() => import("../views/Tasks"));

export const Reports = React.lazy(() => import("../views/Reports"));

export const PublicReports = React.lazy(
  () => import("../views/Reports/PublicReports")
);

export const ManageInventoryCount = React.lazy(
  () => import("../views/ManualInventory/ManualInventoryWrapper")
);

export const AccountSettings = React.lazy(
  () => import("../views/AccountSetttings/AccountSettings")
);

export const AlternativesFinder = React.lazy(
  () => import("../views/AlternativesFinder")
);
