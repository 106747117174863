import React, { useEffect } from "react";
import "whatwg-fetch";
import {
  configureAuth,
  currentAuthenticatedUser,
  notAuthScreen
} from "./services/Auth";

import * as Sentry from "@sentry/browser";
import Routes from "./routes";

import { CognitoUser } from "@aws-amplify/auth";

import { ErrorBoundary } from "react-error-boundary";

import "./App.css";
// Styles
// CoreUI Icons Set
// import "@coreui/icons/css/coreui-icons.min.css";
// Import Flag Icons Set
import "flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
import "font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
import "./scss/style.css";
import GenericErrorMessage from "./components/ErrorHandling/GenericErrorMessage";
import { TAG, TENANT, ENV } from "./config";
import { getUsername } from "./components/CurrentUser/CurrentUser";

const isSentryEnabled = ENV === "dev" || ENV === "local" ? false : true;

Sentry.init({
  dsn: "https://5f2f7084256047e4b4e4f5b1cf55ca49@sentry.io/1400540",
  environment: ENV,
  enabled: isSentryEnabled,
  release: TAG,
  integrations: [
    new Sentry.Integrations.Breadcrumbs({
      console: isSentryEnabled
    })
  ],
  /*
  we are ignoring these benign resizeObserver errors
  see the following for reference:
  https://github.com/WICG/resize-observer/issues/38
  https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/58701523#58701523
  https://github.com/petyosi/react-virtuoso/issues/254#issuecomment-766319461
  */
  ignoreErrors: [
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications."
  ]
});

if (!TENANT) {
  Sentry.captureMessage("URGENT! No Tenant", Sentry.Severity.Fatal);
}
configureAuth();

const getUser = async (): Promise<CognitoUser | any> =>
  await currentAuthenticatedUser();

const App = () => {
  useEffect(() => {
    if (notAuthScreen(location.href))
      try {
        const user: CognitoUser | any = getUser();
        Sentry.configureScope(scope => {
          scope.setUser({ username: getUsername(user) });
          scope.setExtra("tag", TAG);
        });
      } catch (err) {
        console.log(err);
      }
  });

  return (
    <>
      <ErrorBoundary FallbackComponent={GenericErrorMessage}>
        <Routes />
      </ErrorBoundary>
    </>
  );
};
export default App;
