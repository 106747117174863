import React, { useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import Badge from "@material-ui/core/Badge";
import * as Sentry from "@sentry/browser";

import {
  Container,
  Contents,
  StyledAvatar,
  MainContent,
  Footer
} from "./StyledElements";
import LinkButton from "../../LinkButton";
import NotificationActionsMenu from "../NotificationActionsMenu";

const UsageNotification = ({ notification }) => {
  return (
    <Container read={notification.read}>
      <Badge
        color="error"
        overlap="circle"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        variant="dot"
        invisible={notification.read}
      >
        <StyledAvatar color="#0aa46b">U</StyledAvatar>
      </Badge>
      <Contents>
        <MainContent>
          <Message notification={notification} />
        </MainContent>
        <Footer>
          <Typography variant="caption">
            {dayjs(notification.created_date).format("MM/DD/YYYY")} at{" "}
            {dayjs(notification.created_date).format("h:mm a")}
          </Typography>
        </Footer>
      </Contents>
      <NotificationActionsMenu notification={notification} />
    </Container>
  );
};
export default UsageNotification;
UsageNotification.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.number.isRequired,
    read: PropTypes.bool.isRequired,
    created_date: PropTypes.string.isRequired,
    usageNotificationKey: PropTypes.string.isRequired,
    userSetThreshold: PropTypes.number.isRequired,
    drugInventoryHistory: PropTypes.shape({
      id: PropTypes.number.isRequired,
      drug: PropTypes.shape({
        id: PropTypes.number.isRequired,
        active_shortages: PropTypes.arrayOf(PropTypes.number)
      }).isRequired
    }).isRequired
  })
};

/**
 * Display the correct message depending on manual inventory threshold  or not
 */
const Message = ({ notification }) => {
  const truncateString = string => {
    return string.length > 40 ? string.slice(0, 40).concat("...") : string;
  };
  const history = useHistory();
  const navigateToInventoryPanel = shortage => {
    const route = `/shortages?shortage=${shortage.id}&selectedPanel=inventory`;
    history.push(route);
  };

  if (notification.shortageListing) {
    switch (notification.usageNotificationKey) {
      case "BELOW_THRESHOLD":
        return (
          <ManualThresholdMessage
            notification={notification}
            navigateToInventoryPanel={navigateToInventoryPanel}
            truncateString={truncateString}
          />
        );
      default:
        Sentry.captureMessage(
          `unknown key for UsageNotification ${notification.usageNotificationKey}`
        );
        return null;
    }
  } else return null;
};

const ManualThresholdMessage = ({
  notification,
  navigateToInventoryPanel,
  truncateString
}) => {
  const calculatedWeeks = notification.userSetThreshold / 7;
  return (
    <div>
      <Typography
        display="inline"
        style={{ fontSize: ".875rem", lineHeight: "1.2" }}
      >
        <b>Usage Alert: </b>Your total inventory has dropped below{" "}
        {calculatedWeeks} weeks remaining for{" "}
        <LinkButton
          onClick={() => navigateToInventoryPanel(notification.shortageListing)}
          text={`${truncateString(
            notification.shortageListing.primary_product.description
          )}`}
        />{" "}
        (CIN: {notification.shortageListing.primary_product.external_id}).
      </Typography>
    </div>
  );
};
