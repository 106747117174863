import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Routes, secondaryMobileRoutes } from "./routes";

// Material
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import withWidth from "@material-ui/core/withWidth";
import Tooltip from "@material-ui/core/Tooltip";

class DefaultNav extends React.Component {
  logoVisibility = status => {
    document.querySelector("#logo").style.visibility = status;
  };

  renderDrawer = isMobile => {
    const {
      classes,
      isNavOpen,
      isMobileNavOpen,
      expandedSub,
      toggleMobileNav,
      toggleNav,
      updateSelectedSubNav,
      path
    } = this.props;
    const date = new Date();

    return (
      <div>
        <List component="nav">
          {Routes.map((route, index) => (
            <React.Fragment key={`nav-item-${index}`}>
              <Tooltip
                title={isNavOpen || isMobileNavOpen ? "" : `${route.viewValue}`}
              >
                <ListItem
                  button
                  component={route.sub.hasSubNav ? "div" : "a"}
                  href={!route.sub.hasSubNav ? `#${route.route}` : null}
                  key={route.value}
                  selected={path.includes(route.route)}
                  classes={{ selected: classes.selectedListItem }}
                  onClick={() => {
                    if (route.sub.hasSubNav) {
                      updateSelectedSubNav(route.value);
                      if (!isNavOpen) toggleNav();
                    } else {
                      if (isMobileNavOpen) toggleMobileNav();
                    }
                  }}
                  className={classNames({
                    [classes.adjustIcon]: route.adjustHeight
                  })}
                >
                  <ListItemIcon
                    className={classNames({
                      [classes.activeIcon]: path.includes(route.route),
                      [classes.inactiveIcon]: !path.includes(route.route)
                    })}
                  >
                    {route.icon}
                  </ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: path.includes(route.route)
                        ? classes.selected
                        : null
                    }}
                    primary={route.viewValue}
                  />

                  {route.sub.hasSubNav && (
                    <span>
                      {expandedSub === route.value ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </span>
                  )}
                </ListItem>
              </Tooltip>
              {route.sub.hasSubNav && (
                <Collapse
                  in={
                    expandedSub === route.value &&
                    (isNavOpen || isMobileNavOpen)
                  }
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {route.sub.routes.map((sr, index) => (
                      <ListItem
                        key={`sub-nav-item${index}`}
                        button
                        component="a"
                        href={`#${sr.route}`}
                        className={classNames(classes.nested, {
                          [classes.nestedIconItem]: !!sr.icon
                        })}
                        onClick={() => {
                          if (isMobileNavOpen) toggleMobileNav();
                        }}
                      >
                        <ListItemIcon
                          className={classNames({
                            [classes.activeIcon]: path.includes(sr.route),
                            [classes.inactiveIcon]: !path.includes(sr.route),
                            [classes.nestedIcon]: !!sr.icon
                          })}
                        >
                          {sr.icon}
                        </ListItemIcon>
                        {!!sr.icon ? (
                          <ListItemText primary={sr.viewValue} />
                        ) : (
                          <ListItemText
                            inset
                            classes={{
                              inset: classes.inset
                            }}
                            primary={sr.viewValue}
                          />
                        )}
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
        {isMobile && (
          <div>
            <Divider />
            <List>
              {secondaryMobileRoutes.map(route => (
                <ListItem
                  button
                  component={route.sub.hasSubNav ? "div" : "a"}
                  href={!route.sub.hasSubNav ? `#${route.route}` : null}
                  key={route.value}
                  classes={{
                    selected: classes.selectedListItem
                  }}
                  onClick={() => {
                    if (route.sub.hasSubNav) updateSelectedSubNav(route.value);
                  }}
                >
                  <ListItemIcon>{route.icon}</ListItemIcon>
                  <ListItemText primary={route.viewValue} />
                  {route.sub.hasSubNav && (
                    <span>
                      {expandedSub === route.value ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </span>
                  )}
                </ListItem>
              ))}
            </List>
          </div>
        )}
        <div
          className={classNames({
            [classes.invisibleLink]: !isNavOpen
          })}
          style={{
            position: "absolute",
            bottom: 25,
            marginLeft: "20%"
          }}
        >
          <span id="logo" className={classes.linkContain}>
            <a
              className={classes.link}
              href="https://orbitalrx.com"
              target="_blank"
              rel="noopener"
            >
              OrbitalRX
            </a>{" "}
            &copy; {date.getFullYear()}.
          </span>
        </div>
      </div>
    );
  };

  render() {
    const { classes, isNavOpen, isMobileNavOpen, toggleMobileNav, toggleNav } =
      this.props;

    return (
      <nav
        className={classNames({
          [classes.drawer]: isNavOpen,
          [classes.miniDrawer]: !isNavOpen
        })}
      >
        <div className={classes.mobileDrawer}>
          <Drawer
            variant="temporary"
            open={isMobileNavOpen}
            onClose={toggleMobileNav}
            classes={{
              paper: classes.drawerPaper
            }}
          >
            {this.renderDrawer(true)}
          </Drawer>
        </div>

        <div>
          <Drawer
            classes={{
              paper: classNames({
                [classes.drawerPaper]: isNavOpen,
                [classes.miniDrawer]: !isNavOpen
              })
            }}
            className={classNames({
              [classes.drawer]: isNavOpen,
              [classes.miniDrawer]: !isNavOpen
            })}
            variant="permanent"
            onClose={toggleNav}
            open={isNavOpen}
          >
            {this.renderDrawer()}
          </Drawer>
        </div>
      </nav>
    );
  }
}

const styles = theme => ({
  drawer: {
    width: [theme.drawerWidth]
  },
  miniDrawer: {
    width: [theme.navDrawerWidth],
    flexShrink: 0
  },
  mobileDrawer: {
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: [theme.drawerWidth]
  },
  activeIcon: {
    // color: theme.palette.primary.main
    color: theme.palette.primary.active
  },
  inactiveIcon: {
    color: theme.palette.primary.inactive
  },
  adjustIcon: {
    height: 48
  },
  nestedIcon: {
    minWidth: 32
  },
  nestedIconItem: {
    paddingLeft: 70
  },
  root: {
    "&$selected": {
      backgroundColor: "red"
    }
  },
  selectedListItem: {
    backgroundColor: "#F2F6F8 !important"
  },
  listItemText: {
    display: "none",
    "&hover": {
      display: "flex"
    }
  },
  selected: {
    fontWeight: "bold"
  },
  inset: {
    marginLeft: 15
  },
  link: {
    color: "black"
  },
  invisibleLink: {
    visibility: "hidden"
  },
  linkContain: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 15
  }
});

DefaultNav.propTypes = {
  classes: PropTypes.object.isRequired,
  isNavOpen: PropTypes.bool.isRequired,
  isMobileNavOpen: PropTypes.bool.isRequired,
  expandedSub: PropTypes.string.isRequired,
  toggleMobileNav: PropTypes.func.isRequired,
  updateSelectedSubNav: PropTypes.func.isRequired,
  toggleNav: PropTypes.func.isRequired,
  path: PropTypes.string
};

export default withStyles(styles, { withTheme: true })(withWidth()(DefaultNav));
