import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import Badge from "@material-ui/core/Badge";

import {
  Container,
  Contents,
  StyledAvatar,
  MainContent,
  Footer
} from "./StyledElements";
import LinkButton from "../../LinkButton";
import NotificationActionsMenu from "../NotificationActionsMenu";

const ExcessiveUsageNotification = ({ notification }) => {
  return (
    <Container read={notification.read}>
      <Badge
        color="error"
        overlap="circle"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        variant="dot"
        invisible={notification.read}
      >
        <StyledAvatar color="#0aa46b">U</StyledAvatar>
      </Badge>
      <Contents>
        <MainContent>
          <Message notification={notification} />
        </MainContent>
        <Footer>
          <Typography variant="caption">
            {dayjs(notification.created_date).format("MM/DD/YYYY")} at{" "}
            {dayjs(notification.created_date).format("h:mm a")}
          </Typography>
        </Footer>
      </Contents>
      <NotificationActionsMenu notification={notification} />
    </Container>
  );
};
export default ExcessiveUsageNotification;
ExcessiveUsageNotification.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.number.isRequired,
    read: PropTypes.bool.isRequired,
    created_date: PropTypes.string.isRequired,

    expectedWeeklyUsageRate: PropTypes.number.isRequired,
    percentInaccurateThreshold: PropTypes.number.isRequired,
    dispensedQuantity: PropTypes.number.isRequired,
    intervalEnd: PropTypes.string.isRequired,
    intervalWeeks: PropTypes.number.isRequired,
    drug: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      active_shortages: PropTypes.arrayOf(PropTypes.number)
    }).isRequired
  })
};

const Message = ({ notification }) => {
  const truncateString = string => {
    return string.length > 40 ? string.slice(0, 40).concat("...") : string;
  };
  const history = useHistory();
  const navigateToInventoryPanel = () => {
    const route = `/shortages?shortage=${notification.shortageListing.id}&selectedPanel=inventory`;
    history.push(route);
  };

  // don't display notifications that don't have an associated shortage
  if (!notification.shortageListing) {
    return null;
  }

  return (
    <div>
      <Typography
        display="inline"
        style={{ fontSize: ".875rem", lineHeight: "1.2" }}
      >
        <b>Usage Alert: </b>Actual usage has exceeded the expected usage rate:
        <br />
        Usage for{" "}
        <LinkButton
          onClick={() => navigateToInventoryPanel()}
          text={`${truncateString(
            notification.shortageListing.primary_product.description
          )} (CIN:
        ${notification.shortageListing.primary_product.external_id})`}
        />{" "}
        has exceeded its expected usage rate by more than{" "}
        {notification.percentInaccurateThreshold} percent in{" "}
        {notification.intervalWeeks} weeks.
      </Typography>
    </div>
  );
};
