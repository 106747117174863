import React from "react";
import MailIcon from "@material-ui/icons/Mail";
import LocalPharmacyIcon from "@material-ui/icons/LocalPharmacy";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PollIcon from "@material-ui/icons/Poll";
import ListIcon from "@material-ui/icons/List";
import ExitToApp from "@material-ui/icons/ExitToApp";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";

export const Routes = [
  {
    value: "pharmacy",
    route: "shortages",
    viewValue: "Shortages",
    sub: {
      hasSubNav: false
    },
    icon: <LocalPharmacyIcon />,
    adjustHeight: false,
    badge: {
      hasBadge: false
    }
  },
  {
    value: "tasks",
    route: "tasks",
    viewValue: "Tasks",
    sub: {
      hasSubNav: false
    },
    icon: <AssignmentIcon />,
    adjustHeight: false,
    badge: {
      hasBadge: false
    }
  },
  {
    value: "reporting",
    route: "reports",
    viewValue: "Reporting",
    sub: {
      hasSubNav: false
    },
    icon: <PollIcon />,
    adjustHeight: false,
    badge: {
      hasBadge: false
    }
  },
  {
    value: "inventory",
    route: "inventory/manage",
    viewValue: "Manual Inventory",
    sub: {
      hasSubNav: false
    },
    icon: <ListIcon />,
    adjustHeight: true,
    badge: {
      hasBadge: false
    }
  },
  {
    value: "altFinder",
    route: "altfinder",
    viewValue: "Alternatives Finder",
    sub: {
      hasSubNav: false
    },
    icon: <EmojiObjectsIcon />,
    adjustHeight: true,
    badge: {
      hasBadge: false
    }
  }
];

export const secondaryMobileRoutes = [
  {
    value: "logout",
    route: "auth",
    viewValue: "Logout",
    sub: {
      hasSubNav: false
    },
    icon: <ExitToApp />,
    badge: {
      hasBadge: false
    }
  }
];
