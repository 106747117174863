import React from "react";
import PropTypes from "prop-types";
import OrxDrawer from "../OrxDrawer";
import styled from "styled-components";
import NotificationsList from "./NotificationsList";

const NotificationsDrawer = ({
  isOpen,
  setIsOpen,
  notifications,
  markAllRead,
  isError
}) => {
  return (
    <OrxDrawer
      title="Notifications"
      isOpen={isOpen}
      toggleDrawer={() => setIsOpen(false)}
    >
      <Container>
        <Header>
          <a
            style={{ color: "#20a8d8", cursor: "pointer" }}
            onClick={markAllRead}
          >
            Mark all as read
          </a>
        </Header>
        <NotificationsList notifications={notifications} isError={isError} />
      </Container>
    </OrxDrawer>
  );
};
NotificationsDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
      isNew: PropTypes.bool
    })
  ),
  markAllRead: PropTypes.func.isRequired,
  isError: PropTypes.bool
};

export default NotificationsDrawer;

const Container = styled.div`
  margin-left: 2em;
  margin-right: 2em;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 0.5em;
  padding-top: 0.5em;
  align-items: center;
`;
