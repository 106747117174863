import React from "react";
import PropTypes from "prop-types";
import {
  AvailabilityNotification,
  TaskNotification,
  UsageNotification,
  ExcessiveUsageNotification
} from "./NotificationTypes";
import * as Sentry from "@sentry/browser";

const Notification = ({ notification }) => {
  switch (notification.__typename) {
    case "ProductAvailabilityNotification":
      return <AvailabilityNotification notification={notification} />;
    case "TaskNotification":
      return <TaskNotification notification={notification} />;
    case "UsageNotification":
      return <UsageNotification notification={notification} />;
    case "ExcessiveUsageNotification":
      return <ExcessiveUsageNotification notification={notification} />;
    default:
      Sentry.captureMessage(
        `Tried to display a notification that shouldn't be displayed: ${JSON.stringify(
          notification
        )}`
      );
      return null;
  }
};
export default Notification;
Notification.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.number.isRequired,
    read: PropTypes.bool.isRequired,
    created_date: PropTypes.string.isRequired
  })
};
