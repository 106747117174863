import React, { Suspense } from "react";
import {
  Switch,
  Route,
  Redirect,
  HashRouter as Router
} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import AdminRoute from "./AdminRoute";
import Authenticator from "../containers/Authenticator";
import AppWrapper from "../containers/AppWrapper";
import {
  Dashboard,
  ShortageListings,
  Tasks,
  Reports,
  PublicReports,
  Admin,
  ManageInventoryCount,
  AccountSettings,
  AlternativesFinder,
  Loading
} from "./LoadableComponents";

const Routes = () => (
  <Router>
    <Switch>
      <Route path="/auth">
        <Authenticator />
      </Route>
      {/* We should prefer this style rather than setting component prop, because it is more flexible */}
      {/* However, we may need to adjust the component if it expects location, history, and other props provided */}
      {/* to the component */}
      <PrivateRoute path="/dashboard" exact name="Dashboard">
        <AppWrapper>
          <Suspense fallback={<Loading />}>
            <Dashboard />
          </Suspense>
        </AppWrapper>
      </PrivateRoute>
      <PrivateRoute path="/shortages" exact name="Shortage Listings">
        <AppWrapper>
          <Suspense fallback={<Loading />}>
            <ShortageListings />
          </Suspense>
        </AppWrapper>
      </PrivateRoute>
      <PrivateRoute path="/tasks" exact name="Tasks">
        <AppWrapper>
          <Suspense fallback={<Loading />}>
            <Tasks />
          </Suspense>
        </AppWrapper>
      </PrivateRoute>
      <PrivateRoute path="/reports" exact name="Reports">
        <AppWrapper>
          <Suspense fallback={<Loading />}>
            <Reports />
          </Suspense>
        </AppWrapper>
      </PrivateRoute>
      <PrivateRoute path="/reports/public" exact name="Public Reports">
        <AppWrapper>
          <Suspense fallback={<Loading />}>
            <PublicReports />
          </Suspense>
        </AppWrapper>
      </PrivateRoute>
      <AdminRoute path="/admin" exact name="Admin">
        <AppWrapper>
          <Suspense fallback={<Loading />}>
            <Admin />
          </Suspense>
        </AppWrapper>
      </AdminRoute>
      <PrivateRoute path="/settings" exact name="Account Settings">
        <AppWrapper>
          <Suspense fallback={<Loading />}>
            <AccountSettings />
          </Suspense>
        </AppWrapper>
      </PrivateRoute>
      <PrivateRoute path="/inventory/manage" name="Manage Inventory Count">
        <AppWrapper>
          <Suspense fallback={<Loading />}>
            <ManageInventoryCount />
          </Suspense>
        </AppWrapper>
      </PrivateRoute>
      <PrivateRoute path="/altfinder" exact name="Alternatives Finder">
        <AppWrapper>
          <Suspense fallback={<Loading />}>
            <AlternativesFinder />
          </Suspense>
        </AppWrapper>
      </PrivateRoute>
      <Redirect from="/" to="/shortages" />
    </Switch>
  </Router>
);

export default Routes;
