import React, { useState } from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import styled from "styled-components";
import { useSetNotificationReadStatusMutation } from "../../generated/graphql";

const NotificationActionsMenu = ({ notification }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [toggleRead] = useSetNotificationReadStatusMutation({
    variables: {
      id: notification.id,
      typename: notification.__typename,
      read: !notification.read
    }
  });
  return (
    <React.Fragment>
      <StyledIconButton
        color="secondary"
        onClick={event => setAnchorEl(event.currentTarget)}
      >
        <MoreVertIcon />
      </StyledIconButton>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            toggleRead();
            setAnchorEl(null);
          }}
        >
          Mark as {notification.read ? "Unread" : "Read"}
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};
export default NotificationActionsMenu;

const StyledIconButton = styled(IconButton)`
  margin-top: -0.4em;
`;
