import React, { useEffect } from "react";
import * as Sentry from "@sentry/browser";
import LinkButton from "../LinkButton";

interface GenericErrorMessageProps {
  error: any;
  resetErrorBoundary: any;
}

const GenericErrorMessage = ({
  error,
  resetErrorBoundary
}: GenericErrorMessageProps) => {
  useEffect(() => {
    Sentry.captureMessage(error.message);
  }, []);

  return (
    <div
      data-testid="errorBoundary"
      style={{ padding: "1%", fontSize: "1.5em" }}
    >
      <span>Something went wrong; we&apos;re looking into it. </span>
      <LinkButton fontSize={"1em"} onClick={() => resetErrorBoundary()}>
        Return to the Shortage Directory.
      </LinkButton>
    </div>
  );
};

export default GenericErrorMessage;
